import React from 'react'

function PaymentCanceledPage() {
    return (
        <div>
            <p>payment canceled</p>
        </div>
    )
}

export default PaymentCanceledPage
