import React from "react";
import { Content } from "../data/text";
import { SectionTitle } from "../styles/styles";
import Store from "../components/Store";
import { motion } from "framer-motion";
import { PageTransitionAnimation } from "../animations/animations";

function StorePage() {
  return (
    <motion.div
      variants={PageTransitionAnimation}
      initial="in"
      animate="visible"
      exit="out"
    >
      <SectionTitle>{Content.stores.title_zh}</SectionTitle>
      {Content.stores.stores.map((store) => (
        <Store {...store} />
      ))}
    </motion.div>
  );
}

export default StorePage;
