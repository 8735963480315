import React from "react";
import "./App.css";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Link,
  useLocation,
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";
import MenuPage from "./pages/MenuPage";
import StorePage from "./pages/StorePage";
import JoinUsPage from "./pages/JoinUsPage";
import OrderPage from "./pages/OrderPage";
import NavMenu from "./components/NavMenu";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentCanceledPage from "./pages/PaymentCanceledPage";

import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();
  // import {loadStripe} from '@stripe/stripe-js';

  return (
    <div className="App">
      <AnimatePresence initial={false}>
        <Navbar />

        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/order">
            <OrderPage />
          </Route>
          <Route exact path="/payment-success">
            <PaymentSuccessPage />
          </Route>
          <Route exact path="/payment-canceled">
            <PaymentCanceledPage />
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/menu">
            <MenuPage />
          </Route>
          <Route exact path="/stores">
            <StorePage />
          </Route>
          <Route exact path="/join-us">
            <JoinUsPage />
          </Route>
          <Route exact path="/nav-menu">
            <NavMenu />
          </Route>
        </Switch>
        <Footer />
      </AnimatePresence>
    </div>
  );
}

export default App;
