import styled from "styled-components";
import { Switch, Route, BrowserRouter as Router, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Hidden,
  Dialog,
} from "@material-ui/core";

export const PageWideBanner = styled.img`
  width: 100vw;
`;

export const StyledLogo = styled.img`
  width: 150px;
  height: 150px;
`;

export const StyledDialog = styled(Dialog)`
  background-color: #bd7d7d;
`;

export const StyledLink = styled(Link)`
  margin: 1rem;
  padding: 1rem;
  font-size: 1.2rem;
  text-decoration: none;
  color: #bd7d7d;
  transition: all 0.5s ease-out;
  &:hover {
    color: #993333;
    transition: all 0.5s ease-out;
  }
`;
export const StyledSection = styled.section`
  background-color: #993333;
  color: #fff;
  margin: 3rem auto;
  padding: 3rem;
  border: 0.6rem solid #bd9a5f;
  border-radius: 2rem;
`;

export const SectionTitle = styled.h1`
  font-family: "Rye", cursive;
  font-size: 3rem;
  color: #bd9a5f;
`;

export const StyledForm = styled.form`
  margin: 3rem auto;
  padding: 3rem;
  width: 50vw;
  border: 2px dotted #993333;
  border-radius: 1rem;
`;

export const StyledFooter = styled.div`
  margin: 0;
  margin-top: 3rem;
  padding: 1rem;
  width: 100vw;
  background-color: #2f2e2e;
  color: #fff;
`;
