import React, {useState} from "react";
import { Button, Box } from "@material-ui/core";
import sorbet from "../images/sorbet.jpg";
import {
  // CardElement,
  // Elements,
  useStripe,
  // useElements,
} from "@stripe/react-stripe-js";

function OrderPage() {
  // const history = useHistory();\
  const perCupPrice = 30;
  const [quantity, setQuantity] = useState<number>(1);
  const stripe = useStripe();
  // const sorbetPriceId = "price_1J7xiLJuyzc5P0648StAzZVx"; // live
  
  const sorbetPriceId = "price_1J8MLIJuyzc5P064C8zYMkiN"; // test

  const successUrl = window.location.protocol + "//www.chunyangtea.com.hk/success";
  const cancelUrl = window.location.protocol + "//www.chunyangtea.com.hk/canceled";

  // const successUrl = window.location.protocol + 'asia-east2-chun-yang-tea-web-app.cloudfunctions.net/stripeWebhooks'
  const handlePayment = async () => {
    try {
      const result = await stripe?.redirectToCheckout({
        lineItems: [{ price: sorbetPriceId, quantity: quantity }],
        mode: "payment",
        successUrl: successUrl,
        cancelUrl: cancelUrl,
      });

      console.log(result);
      
    } catch (err) {
      console.log(err);
    }
  };

  const addQuantity = () => setQuantity(quantity+1);
  const minusQuantity = () => quantity>1 ? setQuantity(quantity-1 ): setQuantity(1);

  return (
    <div style={{ height: "80vh" }}>
      <h1>Pre Order Page</h1>
      <Box m={2}>
        <img src={sorbet} alt="sorbet" />
      </Box>
      <Box m={2}>
        {/* <Link component={Button} to={{ pathname: "https://google.com" }}>
          BUY
        </Link> */}
        <Button onClick={addQuantity}>+</Button>
        {quantity}
        <Button onClick={minusQuantity}>-</Button>
        </Box><Box m={2}>
        Total: ${quantity * perCupPrice}</Box>

        <Box m={2}>
        <Button variant="contained" color="primary" onClick={handlePayment}>Buy</Button>
      </Box>
    </div>
  );
}

export default OrderPage;
