import React, { useState } from "react";
import { Switch, Route, BrowserRouter as Router, Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Hidden,
  Box,
  Dialog,
} from "@material-ui/core";
import { StyledLink, StyledLogo, StyledDialog } from "../styles/styles";
import { Content } from "../data/text";

import logo from "../images/square-white-logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  // const [navMenuOpen, setNavMenuOpen] = useState(false);
  // const closeNavMenu = () => setNavMenuOpen(false);
  return (
    <Box>
      <Hidden smUp>
        <Box m={2}>
          <StyledLink to="/nav-menu">
            <StyledLogo src={logo} />
          </StyledLink>
        </Box>
        {/* <FontAwesomeIcon icon={faBars} size="1x" /> */}
      </Hidden>
      <Hidden xsDown>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid xs={2} justify="flex-start"></Grid>
          <Grid container xs={8} justify="center" alignItems="center">
            <StyledLink to="/about">{Content.about.title_zh}</StyledLink>
            <StyledLink to="/menu">{Content.menu.title_zh}</StyledLink>
            <StyledLink to="/">
              <StyledLogo src={logo} />
            </StyledLink>
            <StyledLink to="/stores">{Content.stores.title_zh}</StyledLink>
            <StyledLink to="/join-us">{Content.join_us.title_zh}</StyledLink>
          </Grid>

          <Grid container xs={2} justify="flex-end">
            <StyledLink
              target="_blank"
              to={{ pathname: "https://www.instagram.com/chunyangtea_hk/" }}
            >
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </StyledLink>
            <StyledLink
              target="_blank"
              to={{ pathname: "https://www.facebook.com/chunyangteahk/" }}
            >
              <FontAwesomeIcon icon={faFacebook} size="1x" />
            </StyledLink>
          </Grid>
        </Grid>
      </Hidden>
      {/* <StyledDialog
        fullScreen
        open={navMenuOpen}
        onClose={() => setNavMenuOpen(false)}
      >
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <StyledLink to="/about" onClick={closeNavMenu}>
              {Content.about.title_zh}
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink to="/menu" onClick={closeNavMenu}>
              {Content.menu.title_zh}
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink to="/stores" onClick={closeNavMenu}>
              {Content.stores.title_zh}
            </StyledLink>
          </Grid>
          <Grid item xs={12}>
            <StyledLink to="/join-us" onClick={closeNavMenu}>
              {Content.join_us.title_zh}
            </StyledLink>
          </Grid>
        </Grid>
      </StyledDialog>*/}
    </Box>
  );
}

export default Navbar;
