import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Switch, Route, BrowserRouter as Router, Link } from "react-router-dom";
import { init } from "emailjs-com";

// theme
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

// stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// const stripePromise = loadStripe("pk_live_Cx3mkcvxPo1ZlozINE9vxaqZ00tm6WkZbp"); // live

const stripePromise = loadStripe("pk_test_WxIA5J3H9Kzm21p0WpK9nX8e00uSLfFCLK"); // test

// emailjs
init("user_y3HZT3zcoiKw9SYYYezPe");

const theme = createMuiTheme({
  palette: {
    primary: { main: "#993333" },
    secondary: { main: "#bd9a5f" },
    // grey: "#2f2e2e",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <Router>
          <App />
        </Router>
      </Elements>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
