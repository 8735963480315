import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Hidden,
  Dialog,
} from "@material-ui/core";
import { StyledLink, StyledLogo, StyledDialog } from "../styles/styles";
import { Content } from "../data/text";

function NavMenu() {
  return (
    <div>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{ height: '100vh'}}
      >
          <StyledLink to="/about">
            {Content.about.title_zh}
          </StyledLink>
 
          <StyledLink to="/menu">
            {Content.menu.title_zh}
          </StyledLink>
     
          <StyledLink to="/stores">
            {Content.stores.title_zh}
          </StyledLink>
       
          <StyledLink to="/join-us">
            {Content.join_us.title_zh}
          </StyledLink>
      </Grid>
    </div>
  );
}

export default NavMenu;
