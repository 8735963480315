import React from "react";
import { PageWideBanner, SectionTitle } from "../styles/styles";

import { Content } from "../data/text";
import JoinUsForm from "../components/JoinUsForm";
import { motion } from "framer-motion";
import { PageTransitionAnimation } from "../animations/animations";
import BannerImage from "../images/making-tea-boy.jpg";
function JoinUsPage() {
  return (
    <motion.div
      variants={PageTransitionAnimation}
      initial="in"
      animate="visible"
      exit="out"
    >
      <PageWideBanner src={BannerImage} />
      <SectionTitle>{Content.join_us.title_zh}</SectionTitle>
      <JoinUsForm />
    </motion.div>
  );
}

export default JoinUsPage;
