export const Content = {
  home: {
    title_en: "Home",
    title_zh: "主頁",
  },
  about: {
    title_en: "About",
    title_zh: "春陽故事",
    content_en: [
      `Founded in 2017, Chun Yang Tea has exploded to over 100 outlets worldwide - including Canada, China, United States, Malaysia and finally, Singapore.`,
      `Chun Yang Tea is ultimately a modern time milk tea made with traditional tea recipes originated back from the 1800+.`,
    ],
    content_zh: [
      `「 一段歷史的回瞰，讓台灣茶業走向世界。」`,
      `陶德（John Dodd）`,
      `英國 蘇格蘭人，在 1860 年初訪台灣，而後以多家洋行代理人的身份進行貿易考察，畢生致力於推廣台灣農產外銷，帶起茶業的風潮，也因此被譽為「臺灣烏龍茶之父」。 `,
      `1866 年（清代同治 5 年），因走訪台灣北部丘陵地時，洞察水土先天優勢，便與引進福建安溪烏龍茶苗種植；`,
      `1867 年首創機器化製茶，試銷老茶到澳門，受到市場歡迎，同年在大稻埕設茶工廠，為精製茶之起源。 `,
      `1869年，以「福爾摩沙烏龍茶」（Formosa Oolong）為品牌，載運第一批精製烏龍茶銷往美國紐約，並廣受好評，讓台茶成為貿易蓬勃發展的先軀。`,
      `於外商雲集、春陽之際，遠洋貿易巨浪洶湧時，陶德的慧眼獨具，開啟了東方茶飲文化的濫觴。 陶德如春陽般，賦予茶葉貿易開端，挾帶祖先們的揮汗開墾的歷史故事，讓其昇華為追求自由、尊嚴的美麗意義。`,
      `春陽茶事欲用更多元的文化呈現，形塑生活風格的態度展現，藉此傳誦茶的故事。`,
    ],
  },
  menu: {
    title_en: "Menu",
    title_zh: "春陽菜單",
  },
  news: {
    title_en: "News",
    title_zh: "春陽快訊",
    posts: [
      'https://firebasestorage.googleapis.com/v0/b/chun-yang-tea-web-app.appspot.com/o/152079813_526608011654490_182482248964681119_n.jpg?alt=media&token=d1595dc3-3101-46d9-ba65-a15a3262943d',
      'https://firebasestorage.googleapis.com/v0/b/chun-yang-tea-web-app.appspot.com/o/150215313_113671500706199_8443299110075888085_n.jpg?alt=media&token=12e01d6d-1c3f-41cd-8a3a-29c3b7f7254e',
      'https://firebasestorage.googleapis.com/v0/b/chun-yang-tea-web-app.appspot.com/o/149510653_713533622862302_8396082770376270312_n.jpg?alt=media&token=1eaf6c31-d24e-48a9-8931-ab5dd45a6ea8',
      'https://firebasestorage.googleapis.com/v0/b/chun-yang-tea-web-app.appspot.com/o/150034912_3788926794508309_7798283158674723004_n.jpg?alt=media&token=32e078ef-298c-44a0-add6-c898d54dad1b',
      'https://firebasestorage.googleapis.com/v0/b/chun-yang-tea-web-app.appspot.com/o/149182284_132508668730435_7099703893101800184_n.jpg?alt=media&token=fc360da2-217d-4d7a-a306-5b4c8b61d7f1',
      'https://firebasestorage.googleapis.com/v0/b/chun-yang-tea-web-app.appspot.com/o/149022994_431460968296038_3998094440605885986_n.jpg?alt=media&token=61b603e6-0829-4e87-bb56-5871419ab467',
    ]
  },
  stores: {
    title_en: "Stores",
    title_zh: "春陽門店",
    stores: [
      // {
      //   name_en: "Central Shop, Hong Kong",
      //   name_zh: "香港中環店",
      //   address_zh: "中環擺花街52-60號新豐樓2號舖",
      //   phone: "(852) 2866 2225",
      //   // opening_hour: "11 : 00 - 20 : 00",
      //   opening_hour_line_1: "星期一至三及星期六、日：1200 - 2000",
      //   opening_hour_line_2: "星期四及星期五：1100 - 2000",
      //   map_data: { lat: 22.2824385, lng: 114.1511987, zoom: 17 },
      // },
      {
        name_en: "Central Shop, Hong Kong",
        name_zh: "香港尖沙咀店",
        address_zh: "尖沙咀樂道32-34號地下",
        phone: "(852) 2866 2225",
        // opening_hour: "11 : 00 - 20 : 00",
        opening_hour_line_1: "星期一至日：1200 - 1900",
        opening_hour_line_2: "",
        map_data: { lat: 22.2972073, lng: 114.1694756, zoom: 17 },
      },
      {
        name_en: "Causeway Bay Shop, Hong Kong",
        name_zh: "香港銅鑼灣店",
        address_zh: "銅鑼灣謝斐道520號地舖",
        phone: "(852) 2164 1268",
        // opening_hour: "11 : 00 - 23 : 45",
        opening_hour_line_1: "星期一至四，及星期日：1130 - 2315",
        opening_hour_line_2: "星期五及星期六: 1130 - 2345",
        map_data: { lat: 22.2809132, lng: 114.1816618, zoom: 17 },
      },
      // {
      //   name_en: "Sheung Wan Shop, Hong Kong",
      //   name_zh: "香港上環店",
      //   address_zh: "上環禧利街27號富輝商業中心地下A鋪",
      //   phone: "(852) 3793 4185",
      //   // opening_hour: "11 : 00 - 23 : 45",
      //   opening_hour_line_1: "星期一至日：1100 - 2200",
      //   opening_hour_line_2: "",
      //   map_data: { lat: 22.2852975, lng: 114.1491603, zoom: 17 },
      // },
      // {
      //   name_en: "Kwun Tong Shop, Hong Kong",
      //   name_zh: "香港觀塘店",
      //   address_zh: "觀塘駿業街56號中海日升中心地下L鋪",
      //   phone: "(852) 3705 2487",
      //   opening_hour: "11 : 00 - 23 : 45",
      //   opening_hour_line_1: "星期一至日：1100 - 2200",
      //   opening_hour_line_2: "",
      //   map_data: { lat: 22.3110677, lng: 114.2206133, zoom: 17 },
      // },
      // {
      //   name_en: "Shatin Pop-up Shop, Hong Kong",
      //   name_zh: "香港沙田期間限定店",
      //   address_zh: "沙田沙田正街18號新城市廣場一期6樓609號",
      //   phone: "(852) 6463 6686",
      //   // opening_hour: "11 : 00 - 21 : 00",
      //   opening_hour_line_1: "星期一至四：1100 - 2100",
      //   opening_hour_line_2: "星期星期五、六、日及公眾假期：1100 - 2130",
      //   map_data: { lat: 22.3819961, lng: 114.1862941, zoom: 17 },
      // },
    ],
  },
  join_us: {
    title_en: "Join Us",
    title_zh: "加盟春陽",
    form: {
      heading_zh: "聯絡我們",
      heading_en: "Contact Us",
      description_zh:
        "請填寫以下表格，填寫完畢請送出，我們將儘速與您聯繫，感謝您！",
      description_en: "",
    },
  },
  footer: {
    name_en:'',
    name_zh: '香港春陽茶事',
    year: '2021'
  }
};
