import { Typography, Box } from "@material-ui/core";
import React from "react";
import { SectionTitle, StyledSection, PageWideBanner } from "../styles/styles";
import { Content } from "../data/text";
import BlackTeaBg from "../images/black-tea-bg.jpg";
import Drinks from '../images/serving-tea-girl.jpg'; 
import { motion } from "framer-motion";
import { PageTransitionAnimation } from "../animations/animations";

function AboutPage() {
  return (
    <motion.div
      variants={PageTransitionAnimation}
      initial="in"
      animate="visible"
      exit="out"
    >
      <PageWideBanner src={Drinks}/>
      <SectionTitle>{Content.about.title_zh}</SectionTitle>
      <Box m={{xs: 1, sm: 12}}>
        <StyledSection>
          {Content.about.content_zh.map((text) => (
            <Box m={{ xs: 1, sm: 3}}>
              <Typography variant="body1" align="justify">
                {text}
              </Typography>
            </Box>
          ))}
        </StyledSection>
      </Box>
    </motion.div>
  );
}

export default AboutPage;
