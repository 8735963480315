import React from "react";
import { StyledFooter } from "../styles/styles";
import { Content } from "../data/text";
import {Typography} from "@material-ui/core";

function Footer() {
  return (
    <StyledFooter>
      <Typography variant="body1">@ {Content.footer.year} | {Content.footer.name_zh}</Typography>
    </StyledFooter>
  );
}

export default Footer;
