import React from "react";
import { Box, Grid, Container } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import StoreIcon from "@material-ui/icons/Store";
import GoogleMapReact from "google-map-react";
import { StyledSection } from "../styles/styles";
import logo from "../images/square-red-logo.jpg";
export interface MapData {
  lat: number;
  lng: number;
  zoom: number;
}
export interface Store {
  name_en: string;
  name_zh: string;
  address_en?: string;
  address_zh: string;
  phone: string;
  opening_hour?: string;
  opening_hour_line_1: string;
  opening_hour_line_2: string;
  map_data: MapData;
}
function MapMarking() {
  return <img src={logo} style={{ width: "50px", height: "50px" }} />;
}

function Store({
  name_zh,
  address_zh,
  phone,
  opening_hour_line_1,
  opening_hour_line_2,
  map_data,
}: Store) {
  console.log(map_data.lat, map_data.lng);
  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <Grid item xs={12} sm={5}>
          <StyledSection>
            <Box
              m={2}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ width: "2rem" }}>
                <StoreIcon />
              </span>
              <span style={{ marginLeft: "10px" }}>{name_zh}</span>
            </Box>
            <Box
              m={2}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <span style={{ width: "2rem" }}>
                <RoomIcon />
              </span>
              <span style={{ marginLeft: "10px" }}>{address_zh}</span>
            </Box>
            <Box
              m={2}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <span style={{ width: "2rem" }}>
                <PhoneIcon />{" "}
              </span>
              <span style={{ marginLeft: "10px" }}>{phone}</span>
            </Box>
            <Box
              m={2}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <span style={{ width: "2rem" }}>
                <AccessTimeIcon />
              </span>
              <span style={{ marginLeft: "10px" }}>{opening_hour_line_1}</span>
            </Box>{" "}
            <Box
              m={2}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <span style={{ width: "2rem" }}></span>
              <span style={{ marginLeft: "10px" }}>{opening_hour_line_2}</span>
            </Box>
          </StyledSection>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div style={{ height: "50vh", width: "100%", zIndex: -100 }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDcWAWSaf7pSN9_hamK5T4lj-QUAnfoh1k",
              }}
              center={{ lat: map_data.lat, lng: map_data.lng }}
              zoom={map_data.zoom}
              yesIWantToUseGoogleMapApiInternals
            >
              {/* <MapMarking {{lat: map_data.lat, lng:map_data.lng}} /> */}
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Store;
