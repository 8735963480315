import React from "react";
import menu1 from "../images/menu-1.jpeg";
import menu2 from "../images/menu-2.jpeg";
import { Grid } from "@material-ui/core";
function Menu() {
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <img src={menu1} style={{ width: '100%', margin: '1rem auto'}}/>
        </Grid>
        <Grid item xs={12}>
          <img src={menu2} style={{ width: '100%', margin: '1rem auto'}}/>
        </Grid>
      </Grid>
    </>
  );
}

export default Menu;
