export const PageTransitionAnimation = {
  in: {
    x:-300,
    opacity: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  out: {
    x: 300,
    opacity: 0,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  // transition: { duration: 0.5}
};
