import React from "react";
import Menu from "../components/Menu";
import { SectionTitle } from "../styles/styles";
import { Content } from "../data/text";
import { motion } from "framer-motion";
import { PageTransitionAnimation } from "../animations/animations";

function MenuPage() {
  return (
    <motion.div
      variants={PageTransitionAnimation}
      initial="in"
      animate="visible"
      exit="out"
    >
      <SectionTitle>{Content.menu.title_zh}</SectionTitle>
      <Menu />
    </motion.div>
  );
}

export default MenuPage;
