import React from "react";
import GoldBanner from "../images/rect-gold-banner.jpg";
import { motion } from "framer-motion";
import { PageTransitionAnimation } from "../animations/animations";
import { SectionTitle, PageWideBanner } from "../styles/styles";
import { Content } from "../data/text";
import NewsCard from "../components/NewsCard";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Hidden,
  Container,
} from "@material-ui/core";
import InstagramEmbed from "react-instagram-embed";

function HomePage() {
  return (
    <motion.div
      variants={PageTransitionAnimation}
      initial="in"
      animate="visible"
      exit="out"
    >
      <Hidden xsDown>
        <PageWideBanner src={GoldBanner} />
      </Hidden>
      <Container maxWidth="lg">
        <SectionTitle>{Content.news.title_zh}</SectionTitle>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          wrap="wrap"
        >
          {Content.news.posts.map((post) => (
            <Grid item xs={12} sm={4} spacing={2}>
              <img
                src={post}
                style={{ width: "300px", height: "300px", objectFit: "cover" }}
              />
              {/* <NewsCard  /> */}
              {/* <InstagramEmbed
                url="https://www.instagram.com/p/B_cYWwRDbX9Os_6rJTddStJAxUQR7yfRV/"
                clientAccessToken="6aee7ebe1313da856eeb0a7d5219492c|6aee7ebe1313da856eeb0a7d5219492c"
                maxWidth={300}
                hideCaption={true}
                injectScript
              /> */}
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
}

export default HomePage;
