import React, { useState } from "react";
import emailjs from "emailjs-com";

import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  Snackbar,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import { StyledForm } from "../styles/styles";
function JoinUsForm() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const ServiceID = "service_q8ur8tb";
  const TemplateID = "template_vdwqebi";
  const TemplateParams = {
    name, mobile, email, message
  };
  const sendEmail = async () => {
    const result = await emailjs.send(ServiceID, TemplateID, TemplateParams);
    console.log(result);
    return;
  };

  const resetForm = () => {
    setName("");
    setMobile("");
    setEmail("");
    setMessage("");
  };
  return (
    <div>
      <Box m={2}>
        <StyledForm>
          <Box m={{ xs: 0, sm: 12 }}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="name"
                label="姓名"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box m={{ xs: 0, sm: 12 }}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="mobile"
                label="電話"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box m={{ xs: 0, sm: 12 }}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="email"
                label="電郵"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box m={{ xs: 0, sm: 12 }}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="message"
                label="留言"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box m={{ xs: 0, sm: 12 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                name === "" || mobile === "" || email === "" || message === ""
              }
              onClick={() => {
                sendEmail();
                resetForm();
                setOpenSnackbar(true);
              }}
              style={{ width: "100%", maxWidth: "100px", marginTop: "3rem" }}
            >
              送出
            </Button>
          </Box>
        </StyledForm>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="謝謝您的查詢，將有專人與您聯絡。"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <CloseIcon color="secondary" fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default JoinUsForm;
