import React from 'react'

function PaymentSuccessPage() {
    return (
        <div style={{ height: '80vh'}}>
            <p>payment success</p>
        </div>
    )
}

export default PaymentSuccessPage
